
.top-main {
    .l-inner {
        position: relative;
        height: 470px;
        margin-top: -470px;
        .top-copy {
            position: absolute;
            color: #fff;
            font-family: $sub_font;
            font-weight: bold;
            text-shadow: 0 0 15px #000;
            left: 50px;
            top: 130px;
            dt {
                font-size: 3.6rem;
                span { font-size: 4.6rem; }
            }
            dd {
                font-size: 2.0rem;
                
            }
        }
        .other-group {
            background: rgba(255,255,255,0.8);
            position: absolute;
            padding: 20px;
            font-family: $sub_font;
            right: 0;
            top: 103px;
            dt {
                padding: 5px 13px 10px;
                color: $color-main;
                font-weight: bold;
                font-size: 2.0rem;
                border-bottom: 1px solid $color-main;
            }
            dd {
                margin-top: 15px;
                a {
                    display: block;
                    background: $color-sub;
                    padding-left: 7px;
                    padding-right: 7px;
                    line-height: 50px;
                    font-size: 1.6rem;
                    font-weight: bold;
                    text-decoration: none;
                    vertical-align: middle;
                    &:hover {
                        background: $color-main;
                        color: #fff;
                        &:before { color: $color_sub; }
                    }
                    &:before {
                        margin-right: 5px;
                        color: $color_main;
                        font-family: webicon;
                        font-size: 2.9rem;
                        content: "\e901";
                    }
                }
            }
        }
    }
}

.page-content {
	@if $type == "all"{
        padding-bottom: 0;
    }
}

.content-top {
	@if $type == "all"{
        margin: 0 auto;
        h2 {
            display: block;
            background: #fff;
            padding: 50px 0;
            color: $color_main;
            font-family: $sub_font;
            font-size: 2.8rem;
            text-align: center;
            line-height: 1.5;
            span {
                display: inline-block;
                margin-left: 5px;
                margin-top: -5px;
                font-size: 4.0rem;
                vertical-align: middle;
            }
        }
        &.top-first {
            .top-inner {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                margin: 40px 0;
                .service-list {
                    position: relative;
                    width: 50%;
                    height: 380px;
                    .detail {
                        position: absolute;
                        width: 350px;
                        padding: 20px 25px 25px;
                        color: #fff;
                        dt {
                            font-family: $sub_font;
                            font-size: 2.8rem;
                            font-weight: bold;
                            text-align: center;
                            border-bottom: 1px solid #fff;
                        }
                        dd {
                            padding: 25px 10px 0;
                            &.btn-detail {
                                width: 178px;
                                margin: 25px auto 0;
                                padding: 0;
                                border: 1px solid #fff;
                                line-height: 38px;
                                a {
                                    display: block;
                                    padding: 4px 0 3px 20px;
                                    color: #fff;
                                    vertical-align: middle;
                                    text-decoration: none;
                                    &:hover { background: rgba(255,255,255,0.3); color: $color_sub;}
                                    &:before {
                                        margin-right: 5px;
                                        color: $color_sub;
                                        font-family: webicon;
                                        font-size: 2.9rem;
                                        content: "\e900";
                                    }
                                }
                            }
                        }
                    }
                    &:nth-child(1) {
                        background: url(../img/common/bg-business-01.jpg) 100% 0 no-repeat;
                        border-right: 1px solid #fff;
                        border-bottom: 1px solid #fff;
                        .detail {
                            background: rgba(17,112,162,0.9);
                            right: 48px;
                            top: -40px;
                        }
                    }
                    &:nth-child(2) {
                        background: url(../img/common/bg-business-02.jpg) 0 0 no-repeat;
                        border-bottom: 1px solid #fff;
                        border-left: 1px solid #fff;
                        .detail {
                            background: rgba(11,64,156,0.9);
                            left: 48px;
                            top: -40px;
                        }
                    }
                    &:nth-child(3) {
                        background: url(../img/common/bg-business-03.jpg) 100% 0 no-repeat;
                        border-top: 1px solid #fff;
                        border-right: 1px solid #fff;
                        .detail {
                            background: rgba(31,54,150,0.9);
                            right: 48px;
                            bottom: -40px;
                        }
                    }
                    &:nth-child(4) {
                        background: url(../img/common/bg-business-04.jpg) 0 0 no-repeat;
                        border-top: 1px solid #fff;
                        border-left: 1px solid #fff;
                        .detail {
                            background: rgba(2,126,122,0.9);
                            left: 48px;
                            bottom: -40px;
                        }
                    }
                }
            }
        }
        &.top-second {
            .top-inner {
                background: url(../img/common/bg-top-second.jpg) 50% 0 no-repeat;
                min-height: 500px;
                .l-inner {
                    position: relative;
                    min-height: 500px;
                    .align-c {
                        position: absolute;
                        width: 100%;
                        top: 90px;
                        color: #fff;
                        font-family: $sub_font;
                        font-size: 2.6rem;
                        font-weight: bold;
                        text-shadow: 0 0 10px #000;
                        line-height: 2;
                    }
                    .btn-list {
                        position: absolute;
                        width: 290px;
                        left: 160px;
                        top: 230px;
                        //margin-top: 60px;
                        li {
                            background: #10316b;
                            margin-top: 20px;
                            font-family: $sub_font;
                            font-size: 2.2rem;
                            letter-spacing: 0.1em;
                            &:first-child {
                                margin-top: none;
                            }
                            a {
                                display: block;
                                padding: 5px 0 4px 20px;
                                color: #fff;
                                vertical-align: middle;
                                text-decoration: none;
                                &:hover { color: $color_sub;}
                                &:before {
                                    margin-right: 5px;
                                    color: $color_sub;
                                    font-family: webicon;
                                    font-size: 4.0rem;
                                    content: "\e900";
                                }
                            }
                        }
                    }
                    .inquery-list {
                        position: absolute;
                        right: 0;
                        top: 250px;
                        background: rgba(255,255,255,0.8);
                        margin: 0;
                        border: none;
                    }  
                }
            }
        }
	}
}