@charset "UTF-8";
.top-main .l-inner { position: relative; height: 470px; margin-top: -470px; }

.top-main .l-inner .top-copy { position: absolute; color: #fff; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-weight: bold; text-shadow: 0 0 15px #000; left: 50px; top: 130px; }

.top-main .l-inner .top-copy dt { font-size: 3.6rem; }

.top-main .l-inner .top-copy dt span { font-size: 4.6rem; }

.top-main .l-inner .top-copy dd { font-size: 2.0rem; }

.top-main .l-inner .other-group { background: rgba(255, 255, 255, 0.8); position: absolute; padding: 20px; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; right: 0; top: 103px; }

.top-main .l-inner .other-group dt { padding: 5px 13px 10px; color: #0b409c; font-weight: bold; font-size: 2.0rem; border-bottom: 1px solid #0b409c; }

.top-main .l-inner .other-group dd { margin-top: 15px; }

.top-main .l-inner .other-group dd a { display: block; background: #ffce63; padding-left: 7px; padding-right: 7px; line-height: 50px; font-size: 1.6rem; font-weight: bold; text-decoration: none; vertical-align: middle; }

.top-main .l-inner .other-group dd a:hover { background: #0b409c; color: #fff; }

.top-main .l-inner .other-group dd a:hover:before { color: #ffce63; }

.top-main .l-inner .other-group dd a:before { margin-right: 5px; color: #0b409c; font-family: webicon; font-size: 2.9rem; content: "\e901"; }

.page-content { padding-bottom: 0; }

.content-top { margin: 0 auto; }

.content-top h2 { display: block; background: #fff; padding: 50px 0; color: #0b409c; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 2.8rem; text-align: center; line-height: 1.5; }

.content-top h2 span { display: inline-block; margin-left: 5px; margin-top: -5px; font-size: 4.0rem; vertical-align: middle; }

.content-top.top-first .top-inner { display: flex; flex-wrap: wrap; align-items: flex-end; margin: 40px 0; }

.content-top.top-first .top-inner .service-list { position: relative; width: 50%; height: 380px; }

.content-top.top-first .top-inner .service-list .detail { position: absolute; width: 350px; padding: 20px 25px 25px; color: #fff; }

.content-top.top-first .top-inner .service-list .detail dt { font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 2.8rem; font-weight: bold; text-align: center; border-bottom: 1px solid #fff; }

.content-top.top-first .top-inner .service-list .detail dd { padding: 25px 10px 0; }

.content-top.top-first .top-inner .service-list .detail dd.btn-detail { width: 178px; margin: 25px auto 0; padding: 0; border: 1px solid #fff; line-height: 38px; }

.content-top.top-first .top-inner .service-list .detail dd.btn-detail a { display: block; padding: 4px 0 3px 20px; color: #fff; vertical-align: middle; text-decoration: none; }

.content-top.top-first .top-inner .service-list .detail dd.btn-detail a:hover { background: rgba(255, 255, 255, 0.3); color: #ffce63; }

.content-top.top-first .top-inner .service-list .detail dd.btn-detail a:before { margin-right: 5px; color: #ffce63; font-family: webicon; font-size: 2.9rem; content: "\e900"; }

.content-top.top-first .top-inner .service-list:nth-child(1) { background: url(../img/common/bg-business-01.jpg) 100% 0 no-repeat; border-right: 1px solid #fff; border-bottom: 1px solid #fff; }

.content-top.top-first .top-inner .service-list:nth-child(1) .detail { background: rgba(17, 112, 162, 0.9); right: 48px; top: -40px; }

.content-top.top-first .top-inner .service-list:nth-child(2) { background: url(../img/common/bg-business-02.jpg) 0 0 no-repeat; border-bottom: 1px solid #fff; border-left: 1px solid #fff; }

.content-top.top-first .top-inner .service-list:nth-child(2) .detail { background: rgba(11, 64, 156, 0.9); left: 48px; top: -40px; }

.content-top.top-first .top-inner .service-list:nth-child(3) { background: url(../img/common/bg-business-03.jpg) 100% 0 no-repeat; border-top: 1px solid #fff; border-right: 1px solid #fff; }

.content-top.top-first .top-inner .service-list:nth-child(3) .detail { background: rgba(31, 54, 150, 0.9); right: 48px; bottom: -40px; }

.content-top.top-first .top-inner .service-list:nth-child(4) { background: url(../img/common/bg-business-04.jpg) 0 0 no-repeat; border-top: 1px solid #fff; border-left: 1px solid #fff; }

.content-top.top-first .top-inner .service-list:nth-child(4) .detail { background: rgba(2, 126, 122, 0.9); left: 48px; bottom: -40px; }

.content-top.top-second .top-inner { background: url(../img/common/bg-top-second.jpg) 50% 0 no-repeat; min-height: 500px; }

.content-top.top-second .top-inner .l-inner { position: relative; min-height: 500px; }

.content-top.top-second .top-inner .l-inner .align-c { position: absolute; width: 100%; top: 90px; color: #fff; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 2.6rem; font-weight: bold; text-shadow: 0 0 10px #000; line-height: 2; }

.content-top.top-second .top-inner .l-inner .btn-list { position: absolute; width: 290px; left: 160px; top: 230px; }

.content-top.top-second .top-inner .l-inner .btn-list li { background: #10316b; margin-top: 20px; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-size: 2.2rem; letter-spacing: 0.1em; }

.content-top.top-second .top-inner .l-inner .btn-list li:first-child { margin-top: none; }

.content-top.top-second .top-inner .l-inner .btn-list li a { display: block; padding: 5px 0 4px 20px; color: #fff; vertical-align: middle; text-decoration: none; }

.content-top.top-second .top-inner .l-inner .btn-list li a:hover { color: #ffce63; }

.content-top.top-second .top-inner .l-inner .btn-list li a:before { margin-right: 5px; color: #ffce63; font-family: webicon; font-size: 4.0rem; content: "\e900"; }

.content-top.top-second .top-inner .l-inner .inquery-list { position: absolute; right: 0; top: 250px; background: rgba(255, 255, 255, 0.8); margin: 0; border: none; }

/*---------------------------------------------------- media query ----------------------------------------------------*/
/* smart phone
-------------------------------------------------- */
@media only screen and (max-width: 639px) { .top-main .l-inner { position: relative; height: 470px; margin-top: -470px; }
  .top-main .l-inner .top-copy { position: absolute; color: #fff; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-weight: bold; text-shadow: 0 0 15px #000; left: 50px; top: 130px; }
  .top-main .l-inner .top-copy dt { font-size: 3.6rem; }
  .top-main .l-inner .top-copy dt span { font-size: 4.6rem; }
  .top-main .l-inner .top-copy dd { font-size: 2.0rem; }
  .top-main .l-inner .other-group { background: rgba(255, 255, 255, 0.8); position: absolute; padding: 20px; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; right: 0; top: 103px; }
  .top-main .l-inner .other-group dt { padding: 5px 13px 10px; color: #0b409c; font-weight: bold; font-size: 2.0rem; border-bottom: 1px solid #0b409c; }
  .top-main .l-inner .other-group dd { margin-top: 15px; }
  .top-main .l-inner .other-group dd a { display: block; background: #ffce63; padding-left: 7px; padding-right: 7px; line-height: 50px; font-size: 1.6rem; font-weight: bold; text-decoration: none; vertical-align: middle; }
  .top-main .l-inner .other-group dd a:hover { background: #0b409c; color: #fff; }
  .top-main .l-inner .other-group dd a:hover:before { color: #ffce63; }
  .top-main .l-inner .other-group dd a:before { margin-right: 5px; color: #0b409c; font-family: webicon; font-size: 2.9rem; content: "\e901"; } }

/* tablet
-------------------------------------------------- */
@media only screen and (min-width: 640px) and (max-width: 1079px) { .top-main .l-inner { position: relative; height: 470px; margin-top: -470px; }
  .top-main .l-inner .top-copy { position: absolute; color: #fff; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-weight: bold; text-shadow: 0 0 15px #000; left: 50px; top: 130px; }
  .top-main .l-inner .top-copy dt { font-size: 3.6rem; }
  .top-main .l-inner .top-copy dt span { font-size: 4.6rem; }
  .top-main .l-inner .top-copy dd { font-size: 2.0rem; }
  .top-main .l-inner .other-group { background: rgba(255, 255, 255, 0.8); position: absolute; padding: 20px; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; right: 0; top: 103px; }
  .top-main .l-inner .other-group dt { padding: 5px 13px 10px; color: #0b409c; font-weight: bold; font-size: 2.0rem; border-bottom: 1px solid #0b409c; }
  .top-main .l-inner .other-group dd { margin-top: 15px; }
  .top-main .l-inner .other-group dd a { display: block; background: #ffce63; padding-left: 7px; padding-right: 7px; line-height: 50px; font-size: 1.6rem; font-weight: bold; text-decoration: none; vertical-align: middle; }
  .top-main .l-inner .other-group dd a:hover { background: #0b409c; color: #fff; }
  .top-main .l-inner .other-group dd a:hover:before { color: #ffce63; }
  .top-main .l-inner .other-group dd a:before { margin-right: 5px; color: #0b409c; font-family: webicon; font-size: 2.9rem; content: "\e901"; } }

/* pc
-------------------------------------------------- */
@media only screen and (min-width: 1080px) { .top-main .l-inner { position: relative; height: 470px; margin-top: -470px; }
  .top-main .l-inner .top-copy { position: absolute; color: #fff; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; font-weight: bold; text-shadow: 0 0 15px #000; left: 50px; top: 130px; }
  .top-main .l-inner .top-copy dt { font-size: 3.6rem; }
  .top-main .l-inner .top-copy dt span { font-size: 4.6rem; }
  .top-main .l-inner .top-copy dd { font-size: 2.0rem; }
  .top-main .l-inner .other-group { background: rgba(255, 255, 255, 0.8); position: absolute; padding: 20px; font-family: YuGothic, "Yu Gothic", "游ゴシック", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", Meiryo, "メイリオ", "MS PGothic", "ＭＳ Ｐゴシック", sans-serif; right: 0; top: 103px; }
  .top-main .l-inner .other-group dt { padding: 5px 13px 10px; color: #0b409c; font-weight: bold; font-size: 2.0rem; border-bottom: 1px solid #0b409c; }
  .top-main .l-inner .other-group dd { margin-top: 15px; }
  .top-main .l-inner .other-group dd a { display: block; background: #ffce63; padding-left: 7px; padding-right: 7px; line-height: 50px; font-size: 1.6rem; font-weight: bold; text-decoration: none; vertical-align: middle; }
  .top-main .l-inner .other-group dd a:hover { background: #0b409c; color: #fff; }
  .top-main .l-inner .other-group dd a:hover:before { color: #ffce63; }
  .top-main .l-inner .other-group dd a:before { margin-right: 5px; color: #0b409c; font-family: webicon; font-size: 2.9rem; content: "\e901"; } }
